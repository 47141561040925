import styled from "styled-components";
import * as React from "react";
import { BottomNavSegment } from "../components/PageSegments/BottomNav";
import { BasicLayout } from "../layouts/BasicLayout";
import SpecialOfferModal from "../components/Modal/SpecialOfferModal";
import {
  SpecialOfferBar,
  HeroSegment,
  TestimonySegment,
} from "../components/PageSegments/special-offer/landing";
import { useOnboardingContext } from "../components/PageSegments/onboarding/OnboardingContext";
import {
  web_acq_landing_page_click,
  web_acq_landing_page_view,
} from "../services/mixpanel/acquisition-events";
import { fbq } from "../services/pixel";
import { LibrarySegment } from "../components/PageSegments/special-offer/prepayment";
import { ImageTextSection } from "../components/ui/ImageTextSection";

import Image1 from "../content/img/homepage/interactive-visual-learning.webp";
import Image2 from "../content/img/homepage/understand-books.webp";
import Image3 from "../content/img/homepage/available-subjects.webp";
import Image4 from "../content/img/homepage/why-use-imprint.webp";
import Image5 from "../content/img/homepage/imprint-catalog.webp";
import { AppText } from "../components/ui";
import { AppLink } from "../components/ui/AppLink";
import { mobileSize } from "../util/variables";
import theme from "../util/theme";
import { setMixpanelProperties } from "../services/mixpanel/mixpanel";

export default (data: any) => {
  const { setOnboardingLandingPage } = useOnboardingContext();
  const urlParams = new URLSearchParams(data.location.search);
  const fromParam = urlParams.get("from") ?? undefined;
  const gclidParam = urlParams.get("gclid") ?? undefined;

  React.useEffect(() => {
    web_acq_landing_page_view();
    fbq("trackCustom", "landing_page_view");
    setOnboardingLandingPage("web_discount_flow");
  }, [setOnboardingLandingPage, fromParam]);

  React.useEffect(() => {
    if (gclidParam) {
      setMixpanelProperties({
        gclid: gclidParam,
      });
    }
  }, [gclidParam]);

  return (
    <BasicLayout>
      <Main>
        <SpecialOfferBar />
        <HeroSegment />
        <ImageTextSection
          bgColor="#EDEEEF"
          title="Understand books & essential topics in 10 minutes"
          img={{
            src: Image2,
            alt: "people biking through books",
          }}
          content={
            <>
              <AppText>
                There are millions of books and courses out there but it’s hard to make time for the
                dedicated focus these require. With Imprint it’s easy to learn something new
                everyday – if you have time to doom scroll, you have time to use Imprint.
              </AppText>
              <AppText noMargin>
                Imprint breaks concepts down into bite-sized pieces in a mobile-optimized format so
                you can easily learn complex concepts whenever you have time to spare. It’s
                micro-learning made easy. Imprint’s helps you quickly and easily build a daily
                learning habit.
              </AppText>
            </>
          }
        />
        <TestimonySegment />
        <ImageTextSection
          reverse
          title="Interactive Visual Learning"
          img={{
            src: Image1,
            alt: "Interactive visual learning app",
          }}
          content={
            <>
              <AppText>
                Imprint is an interactive learning app that helps you learn information from popular
                books and courses quickly — it’s a visual guide to the world’s most important
                knowledge. We work with experts - professors from top universities, best-selling
                authors and more – to distill complex concepts into key insights.
              </AppText>
              <AppText>
                Go beyond book summaries - with visuals to help you learn key chapter concepts the
                first time and interactive features to help you retain and recall what you’ve
                already learned.
              </AppText>
              <AppText noMargin>
                Join millions of people learning more effectively on Imprint.
              </AppText>
            </>
          }
        />
        <ImageTextSection
          title="What subjects are available on Imprint?"
          bgColor="#EDEEEF"
          img={{
            src: Image3,
            alt: "Imprint learning subjects",
          }}
          content={
            <AppText noMargin>
              Imprint is the best way to learn across a wide range of topics from philosophy,
              psychology, economics, leadership to science and technology and much more.
            </AppText>
          }
          bottomContent={
            <ButtonWrapper>
              <AppLink
                to="/onboarding/questions"
                onClick={() => web_acq_landing_page_click("hero")}
                style={{ margin: "0 auto" }}
              >
                Get Started
              </AppLink>
            </ButtonWrapper>
          }
        />
        <ImageTextSection
          reverse
          title="Why do people use Imprint?"
          img={{
            src: Image4,
            alt: "Understanding tennis through books",
          }}
          content={
            <AppText noMargin>
              Users turn to Imprint to dive deep on interests as well as for personal growth and
              career development. So whether you’re looking to learn philosophy from one of the
              world’s leading experts, dive deep on management skills, or improve your mental
              well-being, Imprint’s learning paths and courses let you choose the right content for
              your needs all in a completely new format.
            </AppText>
          }
        />
        <ImageTextSection
          title="What’s included in Imprint’s catalog?"
          bgColor="#EDEEEF"
          img={{
            src: Image5,
            alt: "Various topics in Imprint's catalog",
          }}
          content={
            <AppText noMargin>
              Our catalog includes visual & interactive guides to best-selling books, original
              content, and in-depth courses. New content is added regularly.
            </AppText>
          }
          bottomContent={
            <ButtonWrapper>
              <AppLink
                to="/onboarding/questions"
                onClick={() => web_acq_landing_page_click("hero")}
                style={{ margin: "0 auto" }}
              >
                Get Started
              </AppLink>
            </ButtonWrapper>
          }
        />
        <LibrarySegment />
        <Spacing />
        <BottomNavSegment />
        <SpecialOfferModal />
      </Main>
    </BasicLayout>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  @media ${mobileSize} {
    margin-top: 40px;
  }
`;

const Spacing = styled.div`
  height: 80px;
  width: 100%;
  background-color: ${theme.PRIMARY_COLOR};
  @media ${mobileSize} {
    height: 44px;
  }
`;
